import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import type { WrapRootElementBrowserArgs } from "gatsby";
import { MainProvider } from "./MainContext";
import theme from "./styles/theme";

export const WrapRootElement = ({
	element,
}: Pick<WrapRootElementBrowserArgs, "element">) => (
	<MainProvider>

		<ChakraProvider theme={theme}>{element}</ChakraProvider>
	</MainProvider>
);
