/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
	useCallback,
} from "react";
import { DirectorProps, MoviesProps } from "./interfaces/registi";
import axios from "axios";
import ModalIframe from "./components/generic/ModalIframe";
import {
	checkSubscriptionsResponse,
	Subscription,
} from "./utils/apiInterfaces";
import { BASE_URL, checkSubscription } from "./utils/apiService";
import { getQueryParam } from "./utils/helpers";
import SubscriptionLoading from "./components/generic/SubscriptionLoading";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { useAccount, WagmiProvider } from "wagmi";
import { arbitrum, mainnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UserFeedBack from "./components/generic/UserFeedBack";
import MegoModal from "./components/generic/MegoModal";
import NotLoggedModal from "./components/generic/NotLoggedModal";
import SubscriptionCheckModal from "./components/generic/SubscriptionCheckModal";
import ConnectionListner from "./components/generic/ConnectionListner";
interface feedbackProps {
	message: string;
	title: string;
}
type MainContextType = {
	isMobile: boolean;
	windowWidth: number;
	directors: DirectorProps[];
	movies: MoviesProps[];
	setOpenModal: (open: boolean) => void;
	handleOldSubscription: (id: string) => Promise<boolean>;
	setOpenCheckSubscription: (open: boolean) => void;
	payedSubscription:
		| Subscription
		| undefined
		| null
		| { subscriptionId: string };
	fetchDirectors: () => Promise<DirectorProps[]>;
	fetchSingleDirector: (id: number) => Promise<DirectorProps[]>;
	fetchMovies: () => Promise<MoviesProps[]>;
	setNotLoggedModal: (open: boolean) => void;
	openFeedBack: ({ title, message }: feedbackProps) => void;
	iframeSrc: string | undefined | null;
	setIframeSrc: (url: string | undefined | null) => void;
	subscriptions: Subscription[] | null;
	setPayedSubscription: (
		sub: Subscription | { subscriptionId: string }
	) => void;
	setSubscriptions: (subscription: Subscription[] | null) => void;
};

const MainContext = createContext<MainContextType>({
	windowWidth: 0,
	isMobile: false,
	setOpenCheckSubscription: open => {},
	directors: [],
	handleOldSubscription: () => Promise.resolve(false),
	openFeedBack: ({ title, message }: feedbackProps) => {},
	fetchDirectors: () => Promise.resolve([]),
	iframeSrc: "",
	setIframeSrc: (url: string | null | undefined) => {},
	setOpenModal: open => {},
	payedSubscription: null,
	movies: [],
	setNotLoggedModal: open => {},
	fetchMovies: () => Promise.resolve([]),
	fetchSingleDirector: () => Promise.resolve([]),
	subscriptions: null,
	setPayedSubscription: (
		subscription: Subscription | { subscriptionId: string }
	) => {},
	setSubscriptions: (subscriptions: Subscription[] | null) => {},
});

interface Web3Store {
	account: string;
	signature: string;
	claimSignature: string;
}

export const MainProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [web3Store, setWeb3Store] = React.useState<Web3Store>({
		account: "",
		signature: "",
		claimSignature: "",
	});
	const [movies, setMovies] = React.useState<MoviesProps[]>([]);
	const [directors, setDirectors] = useState<DirectorProps[]>([]);
	const [windowWidth, setWindowWidth] = useState(0);
	const [isMobile, setIsMobile] = useState(false);
	const [iframeSrc, setIframeSrc] = useState<string | null | undefined>();
	const [subscriptions, setSubscriptions] = useState<Subscription[] | null>(
		null
	);
	const [payedSubscription, setPayedSubscription] = useState<
		Subscription | undefined | { subscriptionId: string } | null
	>();
	const [subscriptionLoading, setSubscriptionLoading] = useState(false);
	const [feedbackData, setFeedbackData] = useState({
		open: false,
		message: "",
		title: "",
	});
	const [notLoggedModal, setNotLoggedModal] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openCheckSubscription, setOpenCheckSubscription] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("megowallet_address")) {
			console.log("address trovato nello storage");
		}
		if (localStorage.getItem("megowallet_signature")) {
			console.log("signature trovata nello storage");
		}
	}, []);

	useEffect(() => {
		if (payedSubscription) {
			localStorage.setItem(
				"payedSubscription",
				JSON.stringify(payedSubscription)
			);
		}
	}, [payedSubscription]);
	useEffect(() => {
		const ps = localStorage.getItem("payedSubscription");
		if (ps) {
			setPayedSubscription(JSON.parse(ps) as Subscription);
		}
	}, []);

	useEffect(() => {
		const subscriptionParam = getQueryParam("subscription");
		console.log({ subscriptionParam });
		if (subscriptionParam) {
			localStorage.setItem(
				"payedSubscription",
				JSON.stringify({ subscriptionId: subscriptionParam })
			);
			localStorage.setItem("subscription", subscriptionParam);
			console.log(`Subscription found in URL: ${subscriptionParam}`);
			setSubscriptionLoading(true);
			setTimeout(() => {
				setSubscriptionLoading(false);
				if (window)
					window.location.href =
						window.location.protocol +
						"//" +
						window.location.host +
						(window.location.pathname === "/web3login"
							? "/profilo"
							: window.location.pathname);
			}, 5000);
		} else {
			const subscriptionLocalStorage =
				localStorage.getItem("subscription");

			if (subscriptionLocalStorage) {
				handleOldSubscription(subscriptionLocalStorage).then(res => {});
				console.log(
					`Subscription found in local storage: ${subscriptionLocalStorage}`
				);
			} else {
				console.log("No subscription found in URL or local storage.");
			}
		}
	}, []);

	useEffect(() => {
		// Check if window is defined (this code runs only on the client side)
		if (typeof window !== "undefined") {
			const handleResize = () => {
				setWindowWidth(window.innerWidth);
				setIsMobile(window.innerWidth < 768);
			};

			// Initial width set
			handleResize();

			// Add event listener for resize
			window.addEventListener("resize", handleResize);

			// Cleanup event listener on unmount
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
		if (localStorage.getItem("megowallet_address")) {
			console.log("address trovato nello storage");
		}
		if (localStorage.getItem("megowallet_signature")) {
			console.log("signature trovata nello storage");
		}
	}, []);
	const fetchMovies = useCallback(async () => {
		const res = await axios.get(BASE_URL + "/movies/get/all");
		setMovies(res.data.movies);
	}, []);

	const fetchDirectors = useCallback(async () => {
		const res = await axios.get(BASE_URL + "/directors/list");
		setDirectors(
			res.data?.directors.map((e: DirectorProps, index: number) => ({
				id: index,
				directorId: index,
				name: e.director_name,
				image: e.director_avatar,
				bio: e.director_bio,
				address: e.directorId,
			}))
		);
		return;
	}, []);

	useEffect(() => {
		const url = new URL(window?.location.href);
		const provider = url.searchParams.get("provider");
		if (provider) {
			console.log("LOGGED WITH");
			if (provider === "apple")
				localStorage.setItem("loggedWithApple", "1");
			if (provider === "google")
				localStorage.setItem("loggedWithGoogle", "1");

			localStorage.setItem("loggedWith", provider);
		}
	}, []);
	const fetchSingleDirector = useCallback(
		async (id: number) => {
			return [directors[id]];
		},
		[directors, directors.length]
	);

	const handleOldSubscription = async (subscriptionParam: string) => {
		if (!subscriptionParam) return false;
		const res = await checkSubscription(subscriptionParam);
		if (
			res.message === "Subscription already paid" ||
			!res.error ||
			res.message === "Subscription validated correctly"
		) {
			const response = res as checkSubscriptionsResponse;
			console.log("SEI UN ABBONATO", res);
			setPayedSubscription({ subscriptionId: subscriptionParam });
			if (response.subscription) {
				setSubscriptions([response.subscription]);
			}
			return true;
		} else {
			setPayedSubscription(undefined);
			return false;
		}
		return false;
	};

	function openFeedBack({ message, title }: feedbackProps) {
		setFeedbackData({ open: true, message, title });
	}

	// Fetch directors on mount
	useEffect(() => {
		fetchDirectors();
		fetchMovies();
	}, []);

	return (
		<WagmiProvider config={wagmiConfig}>
			<QueryClientProvider client={queryClient}>
				<MainContext.Provider
					value={{
						windowWidth,
						directors,
						isMobile,
						handleOldSubscription,
						payedSubscription,
						//@ts-expect-error tof
						fetchDirectors,
						setPayedSubscription,
						iframeSrc,
						setOpenCheckSubscription,
						setIframeSrc,
						movies,
						openFeedBack,
						setNotLoggedModal,
						//@ts-expect-error tof
						fetchMovies,
						fetchSingleDirector,
						web3Store,
						setWeb3Store,
						setOpenModal,
						subscriptions,
						setSubscriptions,
					}}
				>
					<UserFeedBack
						open={feedbackData.open}
						title={feedbackData.title}
						onClose={() => {
							setFeedbackData(prev => ({ ...prev, open: false }));
						}}
						message={feedbackData.message}
					/>
					{subscriptionLoading && <SubscriptionLoading />}
					{children}
					{openModal && (
						<MegoModal
							forceReload
							onClose={() => setOpenModal(false)}
						/>
					)}
					{openCheckSubscription && (
						<SubscriptionCheckModal
							open={openCheckSubscription}
							close={() => setOpenCheckSubscription(false)}
						/>
					)}
					{notLoggedModal && (
						<NotLoggedModal
							open={notLoggedModal}
							openLogin={() => {
								setNotLoggedModal(false);
								setOpenModal(true);
							}}
							close={() => setNotLoggedModal(false)}
						/>
					)}
					<ConnectionListner />
					<ModalIframe
						iframeSrc={iframeSrc}
						onClose={() => setIframeSrc(null)}
						isOpen={!!iframeSrc}
					/>
				</MainContext.Provider>
			</QueryClientProvider>
		</WagmiProvider>
	);
};
const projectId = "7e77a478e8155a93da131897bde6b0c5";
const metadata = {
	name: "Web3Modal",
	description: "Web3Modal Example",
	url: "http://localhost:8001",
	icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum] as const;

const wagmiConfig = defaultWagmiConfig({
	chains,
	projectId,
	metadata,
});

const queryClient = new QueryClient();

createWeb3Modal({
	wagmiConfig,
	projectId,
	// @ts-expect-error typing bug
	chains,
});

export const useMainContext = () => useContext(MainContext);
