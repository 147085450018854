import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Button } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
//@ts-expect-error typing bug
import LoadingSpinner from "./LoadingSpinner";
import { getCurrentOrigin, purgeOriginForMego } from "../../utils/helpers";

//endregion
export default function MegoModal({
	onClose,
	forceReload,
}: {
	onClose: () => void;
	forceReload: boolean;
}) {
	const { open } = useWeb3Modal();
	const { address, isConnecting, isDisconnected } = useAccount();
	const [loading, setLoading] = React.useState(false);
	const [origin, setOrigin] = useState("");

	const checkSignedWith = () => {
		if (localStorage.getItem("signedWithGoogle") === "1") {
			localStorage.removeItem("signedWithGoogle");
			localStorage.setItem("isGoogleSigned", "1");
		}
		if (localStorage.getItem("signedWithApple") === "1") {
			localStorage.removeItem("signedWithApple");
			localStorage.setItem("isGoogleSigned", "1");
		}
	};
	useEffect(() => {
		if (address) {
			onClose();
			localStorage.setItem("megowallet_address", address);
			localStorage.setItem("web3Session", address);
			if (forceReload) {
				window?.location.reload();
			}
		}
	}, [address]);

	useEffect(() => {
		const url = new URL(window.location.href);

		setOrigin(getCurrentOrigin);

		const signedAs =
			url.searchParams.get("loggedAs") ||
			url.searchParams.get("loggedAs");

		if (signedAs !== undefined && signedAs !== null) {
			checkSignedWith();
			localStorage.setItem("megowallet_address", signedAs);
			localStorage.setItem("web3Session", signedAs);
			if (forceReload) {
				window?.location.reload();
			}
		}
	}, []);

	const logWithApple = () => {
		localStorage.setItem("signingWithApple", "1");
		setLoading(true);
		if (window)
			//@ts-expect-error typing bug
			window.location = `https://wallet.mego.tools/auth/apple?origin=${purgeOriginForMego(origin)}`;
	};

	const logWithGoogle = () => {
		localStorage.setItem("signingWithGoogle", "1");
		setLoading(true);
		if (window)
			//@ts-expect-error typing bug
			window.location = `https://wallet.mego.tools/auth/google?origin=${purgeOriginForMego(origin)}`;
	};

	return (
		<div
			className="fixed left-0 top-0 right-0 bottom-0"
			style={{ width: "100vw", height: "100vh", background: "#0009" }}
		>
			{loading && <LoadingSpinner />}
			{!loading && (
				<div className="flex items-center justify-center h-full">
					<div
						className="m-auto"
						onClick={ev => ev.stopPropagation()}
						style={{
							backgroundColor: "#000",
							padding: 16,
							borderRadius: 16,
						}}
					>
						<img
							src="/images/logoWhite.svg"
							alt="logo"
							width="280px"
						/>
						<br />
						<br />
						<Button
							{...megoButtonStyle}
							onClick={() => (address ? close() : open())}
						>
							CONNECT WITH WALLET CONNECT
						</Button>
						<br />
						<br />
						<Button {...megoButtonStyle} onClick={logWithApple}>
							CONNECT WITH APPLE
						</Button>
						<br />
						<br />
						<Button {...megoButtonStyle} onClick={logWithGoogle}>
							CONNECT WITH GOOGLE
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

const megoButtonStyle = {
	borderRadius: 25,
	fontWeight: 100,
	width: "280px",
	bg: "white",
	p: 8,
	color: "black",
	fontFamily: "system-ui",
};
