import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
	getCurrentOrigin,
	getSession,
	loggedAs,
	signMessageWithApple,
	signMessageWithGoogle,
} from "../../utils/helpers";
import { useSignMessage } from "wagmi";
import { getSubscriptions } from "../../utils/apiService";
import {
	getSubscriptionsResponse,
	Subscription,
} from "../../utils/apiInterfaces";
import { navigate } from "gatsby";
import { useMainContext } from "../../MainContext";
interface Props {
	open: boolean;
	close: () => void;
	isAbsolute?: boolean;
}

const CHECK_SUB_CHALLANGE = "Checking user subscription";
export default function SubscriptionCheckModal({
	open,
	close,
	isAbsolute,
}: Props) {
	const { signMessage, data } = useSignMessage();
	const [, setLoading] = React.useState(false);
	const [subscriptions, setSubscriptions] = React.useState<Subscription[]>(
		[]
	);
	const { setPayedSubscription } = useMainContext();
	const [checkedSub, setCheckedSub] = React.useState(false);
	const [originForStripe, setOriginForStripe] = React.useState("");
	let url;

	const checkSubscriptionsSign = async () => {
		sessionStorage.setItem(
			"checkedSubFor",
			localStorage.getItem("web3Session")!
		);
		if (loggedAs() === "apple") {
			signMessageWithApple(getCurrentOrigin(), CHECK_SUB_CHALLANGE);
			return;
		} else {
			if (loggedAs() === "google") {
				signMessageWithGoogle(getCurrentOrigin(), CHECK_SUB_CHALLANGE);
				return;
			}
		}
		signMessage({ message: CHECK_SUB_CHALLANGE });
	};

	useEffect(() => {
		const session = getSession();
		url = new URL(window?.location?.href);
		const signature = url.searchParams.get("signature");
		console.log(session && (signature || data), signature, data);
		if (session && (signature || data)) {
			setLoading(true);
			getSubscriptions({
				address: session,
				challenge: CHECK_SUB_CHALLANGE,
				signature: signature || data || "",
				origin: originForStripe,
			})
				.then(res => {
					console.log({ res });
					const response = res as getSubscriptionsResponse;
					if (res.error) {
						close();
					}
					if (response.subscriptions?.length > 0) {
						checksubs(response.subscriptions);
					}
				})
				.finally(() => {
					setCheckedSub(true);
					setLoading(false);
				});
		}
	}, [data]);
	const checksubs = (subscriptions: Subscription[] | null) => {
		if (subscriptions && subscriptions?.length > 0) {
			console.log({ subscriptions });
			if (
				subscriptions.filter(sub => sub?.paid && !sub?.expired)
					?.length > 0
			) {
				if (subscriptions[0]?.subscriptionId) {
					//payed return to source
					localStorage.setItem(
						"subscription",
						subscriptions[0].subscriptionId
					);
					setPayedSubscription(subscriptions[0]);

					localStorage.setItem(
						"payedSubscription",
						JSON.stringify(subscriptions[0])
					);
				}

				if (window) {
					window.location.reload();
				}
			} else {
				const toPay = subscriptions.find(
					sub => !sub?.paid && !sub?.expired
				);
				if (toPay) {
					localStorage.setItem("toPay", JSON.stringify(toPay));
					if (window) {
						window.location.reload();
					}
				}
			}
		} else {
			if (checkedSub) {
				console.log("NON SEI ABBONATO");
			}
		}
	};

	if (!open) return null;
	return (
		<Flex
			className="left-0 top-0 bottom-0 right-0 items-center justify-center z-50"
			position={isAbsolute ? "absolute" : "fixed"}
		>
			<Box
				borderRadius={12}
				borderWidth="2px"
				padding="16px"
				bg="#0f0f0f"
				borderColor="#1f1f1f"
				w="320px"
			>
				<Heading className="text-3xl" color="#ffce00">
					Attenzione!
				</Heading>
				<Text className="font-secondary text-sm">
					Per controllare se sei un abbonato, per questioni di
					sicurezza e privacy devi firmare la richiesta attraverso il
					tuo account
				</Text>
				<Flex className="items-center justify-end gap-2 mt-4">
					<Button
						className="px-3 pb-1"
						pt="6px"
						onClick={checkSubscriptionsSign}
						bg="#ffce00"
						color="#000"
						borderRadius={8}
					>
						Controllo
					</Button>
				</Flex>
			</Box>
		</Flex>
	);
}

export { CHECK_SUB_CHALLANGE };
