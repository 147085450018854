import { extendTheme } from "@chakra-ui/react";

const colors = {
	background: "#141414", // Colore di background
	text: "#ffffff", // Colore del testo
	primary: "#ffce00", // Colore principale
	componentBg: "#1a1a1a", // Background dei componenti
	distributionCardBg: "#1d1d1d",
	mutedText: "#999999", // Testo più spento
	lightText: "#999999", // Testo più spento
	darkBg: "#0f0f0f", // Background più scuro
	borderColor: "#1f1f1f",
};

const theme = extendTheme({
	fonts: {
		heading: "'Bebas Neue', sans-serif",
		body: "'Bebas Neue', monospace",
	},
	colors: {
		black: colors.background,
		white: colors.text,
		primary: colors.primary,
		distributionCardBg: colors.distributionCardBg,
		componentBg: colors.componentBg,
		mutedText: colors.mutedText,
		lightText: colors.lightText,
		darkBg: colors.darkBg,
		borderColor: colors.borderColor,
	},
	styles: {
		global: {
			body: {
				bg: colors.background,
				color: colors.text,
			},
		},
	},
	components: {
		Heading: {
			baseStyle: {
				fontWeight: "normal",
			},
		},
		Button: {
			baseStyle: {
				bg: colors.componentBg,
				color: colors.text,
			},
		},
		// Puoi aggiungere altri componenti qui
	},
});

export default theme;
