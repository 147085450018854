import axios from "axios";
import {
	checkSubscriptionsResponse,
	ClaimContentsRequest,
	ClaimContentsResponse,
	ErrorResponse,
	GetOwnedNftsRequest,
	getSubscriptionBody,
	getSubscriptionsResponse,
	InitSubscriptionBody,
	OwnedNftsResponse,
	RedeemedMoviesRequest,
	RedeemedMoviesResponse,
	RedeemSubscriptionRequest,
	RedeemSubscriptionResponse,
	SubscriptionCreatedResponse,
} from "./apiInterfaces";

const BASE_URL =
	// "http://localhost:3000" ||
	"https://z1i3eeoka7.execute-api.us-east-1.amazonaws.com/dev";

const endPoints = {
	getSub: "/subscriptions/list",
	sub: "/subscriptions",
	checkSub: "/subscriptions/check",
	claim: "/nfts/contents",
	redeemSubscription: "/subscriptions/redeem",
	returnOwnedNfts: "/nfts/owned",
	returnRedeemedMovies: "/subscriptions/redeemed",
};

const errorResponse = {
	message: "Errore impreviso provare piú tardi",
	error: true,
};

const getSubscriptions = async (
	body: getSubscriptionBody
): Promise<getSubscriptionsResponse | ErrorResponse> => {
	try {
		const res = await axios.post<getSubscriptionsResponse>(
			BASE_URL + endPoints.getSub,
			body
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		return errorResponse;
	}
	return errorResponse;
};

const initSubscription = async (
	body: InitSubscriptionBody
): Promise<SubscriptionCreatedResponse | ErrorResponse> => {
	try {
		const res = await axios.post<SubscriptionCreatedResponse>(
			BASE_URL + endPoints.sub,
			body
		);
		if (res.status === 200) {
			const { data } = res;

			return data;
		}
	} catch (e) {
		return errorResponse;
	}
	return errorResponse;
};
const checkSubscription = async (
	subscription: string
): Promise<checkSubscriptionsResponse | ErrorResponse> => {
	try {
		const res = await axios.post<checkSubscriptionsResponse>(
			BASE_URL + endPoints.checkSub,
			{ subscription }
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		return errorResponse;
	}
	return errorResponse;
};

const claimContents = async (
	request: ClaimContentsRequest
): Promise<ClaimContentsResponse | ErrorResponse> => {
	try {
		const res = await axios.post<ClaimContentsResponse>(
			BASE_URL + endPoints.claim,
			request
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		console.error(e);
		return errorResponse;
	}
	return errorResponse;
};
const getOwnedNfts = async (
	request: GetOwnedNftsRequest
): Promise<OwnedNftsResponse | ErrorResponse> => {
	try {
		const res = await axios.get<OwnedNftsResponse>(
			`${BASE_URL}${endPoints.returnOwnedNfts}/${request.tier}/${request.address}`
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		console.error(e);
		return errorResponse;
	}
	return errorResponse;
};

const redeemSubscription = async (
	request: RedeemSubscriptionRequest
): Promise<RedeemSubscriptionResponse | ErrorResponse> => {
	try {
		const res = await axios.post<RedeemSubscriptionResponse>(
			BASE_URL + endPoints.redeemSubscription,
			request
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		console.error(e);
		return errorResponse;
	}
	return errorResponse;
};

const returnRedeemedMoviesByAddress = async (
	request: RedeemedMoviesRequest
): Promise<RedeemedMoviesResponse | ErrorResponse> => {
	try {
		const res = await axios.post<RedeemedMoviesResponse>(
			BASE_URL + endPoints.returnRedeemedMovies,
			request
		);
		if (res.status === 200) {
			const { data } = res;
			return data;
		}
	} catch (e) {
		console.error(e);
		return errorResponse;
	}
	return errorResponse;
};

export {
	getSubscriptions,
	initSubscription,
	checkSubscription,
	claimContents,
	getOwnedNfts,
	redeemSubscription,
	returnRedeemedMoviesByAddress,
	BASE_URL,
};
