import "./src/styles/global.css";
import React from "react";
import {WrapRootElement} from "./src/providers";
import {GatsbyBrowser} from "gatsby";
// @ts-expect-error ther is no typed library
import {CookieNotice} from 'gatsby-cookie-notice';

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] =
    ({element}) => (
        <>

            <WrapRootElement element={element}/>
            <CookieNotice
                personalizeButtonText={"I want to choose my cookies !"}
                declineButtonText={"Rifiuta"}
                acceptButtonText={"Accetta"}
                cookies={[
                    {
                        name: 'necessary',
                        editable: false,
                        default: true,
                        title: 'Necessari',
                        text: 'I cookie essenziali sono necessari per il corretto funzionamento del sito.'
                    }
                ]}
                declineButton={true} // Nasconde il pulsante di rifiuto
                personalizeButtonEnable={false} // Nasconde il pulsante di personalizzazione
            >
                <div>
                <h4 style={{color:"#ffce00",fontSize:'1.3rem'}}>Utilizzo dei Cookies</h4>
                    <p style={{fontFamily: 'Andale mono', fontSize: '0.89rem'}}>Utilizziamo solo cookies essenziali per
                        garantire il corretto funzionamento del sito. Nessun cookie di tracciamento o pubblicità viene
                        utilizzato. <a style={{minWidth: 'max-content',color:"#ffce00"}} href={'/privacy-policy#cookies'}
                                       className={"ml-auto mr-8"} target={'_blank'} rel="noopener noreferrer">
                            Cookie policy
                        </a></p>
                </div>

            </CookieNotice>
        </>);
