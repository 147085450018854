import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
interface Props {
	open: boolean;
	close: () => void;
	openLogin: () => void;
}
export default function NotLoggedModal({ open, close, openLogin }: Props) {
	if (!open) return null;
	return (
		<Flex
			bg="#000a"
			className="fixed left-0 top-0 bottom-0 right-0 items-center justify-center "
			zIndex={100}
		>
			<Box
				borderRadius={12}
				borderWidth="2px"
				padding={16}
				bg="#0f0f0f"
				borderColor="#1f1f1f"
				w="320px"
			>
				<Heading className="text-3xl" color="#ffce00">
					Attenzione!
				</Heading>
				<Text className="font-secondary text-sm">
					Per visualizzare la pagina devi effettuare la login
				</Text>
				<Flex className="items-center justify-end gap-2 mt-4">
					<Button
						className="px-3 pb-1"
						pt="6px"
						bg="#ffce00"
						onClick={() => {
							if (window) window.location.href = "/";
							close();
						}}
						color="#000"
						borderRadius={8}
					>
						Riportami alla home
					</Button>
					<Button
						className="px-3 pb-1"
						pt="6px"
						onClick={openLogin}
						bg="#ffce00"
						color="#000"
						borderRadius={8}
					>
						Login
					</Button>
				</Flex>
			</Box>
		</Flex>
	);
}
