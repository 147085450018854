import {CircularProgress} from "@chakra-ui/react";
import React from "react";

export default function  LoadingSpinner() {

    return <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0}}>
        <div style={{display: 'flex', height: "100%", justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress isIndeterminate color={"#ffce00"}/>
        </div>
    </div>
}