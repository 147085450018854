import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import React from "react";

export default function UserFeedBack({open, title, onClose, message}: {
    open: boolean;
    title: string;
    onClose: () => void;
    message: string;
}) {
    console.log(open)
    if(!open)return <></>;
    return (
        <Flex justify={"center"} align={"center"} className={"fixed left-0 z-20 top-0 bottom-0 right-0 "} bg={"#000a"}
             >
            <Box width={"340px"} bgColor={"#0f0f0f"} p={12} pt={4}  borderColor={"#444a"} borderWidth={2}  borderRadius={12}>
                <Heading color={"#ffce00"} className={"text-3xl text-center"}>{title}</Heading>
                <Text className={"font-secondary text-sm"}>{message}</Text>
                <div className={"flex items-end"}>
                <Button bgColor={"#ffce00"} color={"#000"} onClick={onClose}  borderRadius={4} px={8} py={2} ml={"auto"}>Chiudi</Button>
                </div>
            </Box>
        </Flex>
    )
}