import { Box, CircularProgress, Flex, Heading } from "@chakra-ui/react";
import React from "react";

export default function SubscriptionLoading() {
	return (
		<Flex
			direction="column"
			justify="center"
			align="center"
			className="fixed z-40 left-0 right-0 top-0 bottom-0 "
			bg={"#000" + "a"}
		>
			<Heading color="#ffce00" className="text-4xl text-center">
				Caricamento Abbonamento in corso
			</Heading>
			<CircularProgress
				isIndeterminate
				color="#ffce00"
			></CircularProgress>
		</Flex>
	);
}
