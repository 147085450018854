exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-claim-film-tsx": () => import("./../../../src/pages/claim-film.tsx" /* webpackChunkName: "component---src-pages-claim-film-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-distribuzione-tsx": () => import("./../../../src/pages/distribuzione.tsx" /* webpackChunkName: "component---src-pages-distribuzione-tsx" */),
  "component---src-pages-festival-tsx": () => import("./../../../src/pages/festival.tsx" /* webpackChunkName: "component---src-pages-festival-tsx" */),
  "component---src-pages-film-movie-id-tsx": () => import("./../../../src/pages/film/[movieId].tsx" /* webpackChunkName: "component---src-pages-film-movie-id-tsx" */),
  "component---src-pages-film-tsx": () => import("./../../../src/pages/film.tsx" /* webpackChunkName: "component---src-pages-film-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profilo-tsx": () => import("./../../../src/pages/profilo.tsx" /* webpackChunkName: "component---src-pages-profilo-tsx" */),
  "component---src-pages-proponi-un-film-tsx": () => import("./../../../src/pages/proponi-un-film.tsx" /* webpackChunkName: "component---src-pages-proponi-un-film-tsx" */),
  "component---src-pages-registi-director-id-tsx": () => import("./../../../src/pages/registi/[directorId].tsx" /* webpackChunkName: "component---src-pages-registi-director-id-tsx" */),
  "component---src-pages-registi-tsx": () => import("./../../../src/pages/registi.tsx" /* webpackChunkName: "component---src-pages-registi-tsx" */),
  "component---src-pages-termini-e-condizioni-tsx": () => import("./../../../src/pages/termini-e-condizioni.tsx" /* webpackChunkName: "component---src-pages-termini-e-condizioni-tsx" */),
  "component---src-pages-web-3-login-tsx": () => import("./../../../src/pages/web3login.tsx" /* webpackChunkName: "component---src-pages-web-3-login-tsx" */)
}

