import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import React from "react";
export default function ConnectionListner() {
	const { isDisconnected, isConnecting, isConnected } = useAccount();
	const [wasConnected, setWasConnected] = useState(false);

	useEffect(() => {
		if (isConnected && !wasConnected) {
			setWasConnected(true);
		} else if (
			wasConnected &&
			!isConnected &&
			!isConnecting &&
			isDisconnected
		) {
			setWasConnected(false);
			localStorage.clear();
			sessionStorage.clear();
			window.location.reload();
		}
	}, [isConnected, wasConnected]);
	return <></>;
}
