import React, { useEffect } from "react";
import {
	Box,
	Button,
	Modal,
	ModalContent,
	ModalBody,
	useDisclosure,
} from "@chakra-ui/react";

const ModalIframe = ({ isOpen, onClose, iframeSrc }: any) => {
	const {} = useDisclosure();

	useEffect(() => {
		if (isOpen) {
			const script1 = document.createElement("script");
			script1.src =
				"https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
			script1.async = true;

			document.body.appendChild(script1);

			script1.onload = () => {
				// @ts-expect-error non puo sapere che esiste a questo punto
				window.jotformEmbedHandler(
					`iframe[src='${iframeSrc}']`,
					"https://form.jotform.com/"
				);
			};

			return () => {
				document.body.removeChild(script1);
			};
		}
	}, [isOpen, iframeSrc]);

	return (
		<Box p={4}>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalContent
					zIndex={20000}
					maxW="500px"
					mt="84px"
					mx="auto"
					overflow="auto"
				>
					<ModalBody
						borderWidth="5px"
						borderColor="#ffce00"
						borderRadius="4px"
					>
						<iframe
							src={iframeSrc}
							title="Form"
							style={{
								minWidth: "100%",
								minHeight: "720px",
								border: "none",
								overflow: "auto",
							}}
							frameBorder="0"
							allow="geolocation; microphone; camera; fullscreen"
						/>
					</ModalBody>
					<Button
						position="absolute"
						right={12}
						top={24}
						zIndex={200}
						width="30px"
						lineHeight="35px"
						height="30px"
						className="text-secondary"
						borderRadius="50%"
						bg="black"
						color="primary"
						onClick={onClose}
					>
						x
					</Button>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default ModalIframe;
