import { initSubscription } from "./apiService";
import { Subscription, SubscriptionCreatedResponse } from "./apiInterfaces";

const getQueryParam = (param: string, search?: string) => {
	if (!window) return "";
	let urlParams;
	if (search) urlParams = new URLSearchParams(search);
	else if (window) urlParams = new URLSearchParams(window?.location.search);
	if (!urlParams) return "";
	return urlParams.get("subscription");
};

const signMessageWithApple = (origin: string, challange: string) => {
	localStorage.setItem("reddeemWithMego", "1");
	if (window)
		//@ts-expect-error typing bug
		window.location = `https://wallet.mego.tools/auth/apple?origin=${origin.replace("http://", "").replace("https://", "")}&message=${challange}`;
};

const signMessageWithGoogle = (origin: string, challange: string) => {
	localStorage.setItem("redeemWithMego", "1");
	if (window)
		//@ts-expect-error typing bug
		window.location = `https://wallet.mego.tools/auth/google?origin=${origin.replace("http://", "").replace("https://", "")}&message=${challange}`;
};
const getCurrentOrigin = () => {
	let url: URL;
	let origin = "";
	if (window) {
		url = new URL(window.location.href);
		origin = url.host + url.pathname;
		if (origin.indexOf("http") <= -1) {
			if (url.host.indexOf("localhost") <= -1) {
				origin = `https://${origin}`;
			} else {
				origin = `http://${origin}`;
			}
		}
	}
	return origin;
};

const checkIfThereAreOldSub = ({
	subscriptions,
}: {
	subscriptions: Subscription[];
}) => {
	if (subscriptions && subscriptions?.length > 0) {
		console.log({ subscriptions });
		if (
			subscriptions.filter(sub => sub?.paid && !sub?.expired)?.length > 0
		) {
			//payed return to source
			if (subscriptions[0]?.subscriptionId) {
				localStorage.setItem(
					"subscription",
					subscriptions[0].subscriptionId
				);
				localStorage.setItem(
					"payedSubscription",
					JSON.stringify(subscriptions[0])
				);
			}

			return subscriptions.filter(sub => sub?.paid && !sub?.expired);
		} else {
			return false;
		}
	} else {
		return false;
	}
};

const startSubscriptionProcess = async (address: string, origin: string) => {
	const res = await initSubscription({
		address,
		origin:
			origin ||
			localStorage.getItem("stripeRedirect") ||
			window.location.host,
	});
	const response = res as SubscriptionCreatedResponse;
	if (response.pending) {
		if (window) {
			window.location.href = response.pending.stripePaymentLink.url;
		}
		return;
	}
	if (res.error) {
		alert(res.message);
		return;
	}
	if (window) {
		window.location.href = response.link.url;
	}
};

const purgeOriginForMego = (origin: string) => {
	return origin.replace("http://", "").replace("https://", "");
};
const getSession = () =>
	localStorage ? localStorage?.getItem("web3Session") : null;
const loggedAs: () => "google" | "apple" | "walletConnect" = () =>
	//@ts-expect-error i need those three so is faster like this/ZX
	localStorage ? localStorage?.getItem("loggedWith") : null;

const getPayedSubscription = () =>
	localStorage ? localStorage?.getItem("payedSubscription") : null;
const getToPaySubscription = () => {
	return localStorage ? localStorage?.getItem("toPay") : null;
};
export {
	getSession,
	getQueryParam,
	signMessageWithApple,
	getPayedSubscription,
	getToPaySubscription,
	checkIfThereAreOldSub,
	signMessageWithGoogle,
	getCurrentOrigin,
	loggedAs,
	startSubscriptionProcess,
	purgeOriginForMego,
};
